<!--
* @description:
* @fileName shopManage.vue
* @author lxs
* @date 2022/08/30 14:21
!-->
<template>
  <div>
    <vab-query-form class="ml-10" style="padding-top: 20px">
      <vab-query-form-left-panel :span="24">
        <el-form :inline="true" :model="state.queryForm" @submit.prevent>
          <!-- <el-form-item label="门票状态">
            <el-select
              v-model="state.queryForm.status"
              class="m-2"
              placeholder="请选择门票状态"
              @change="handleChangeSelect"
            >
              <el-option
                v-for="item in state.typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item> -->

          <el-form-item>
            <el-button icon="Plus" type="primary" @click="handleAdd">
              添加渠道
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-left-panel>
    </vab-query-form>
    <el-table v-loading="loading" border :data="state.list">
      <el-table-column align="center" label="渠道名称" prop="channelName" show-overflow-tooltip />
      <el-table-column align="center" label="渠道名称号" prop="channelNo" show-overflow-tooltip />
      <el-table-column align="center" label="渠道备注" prop="remarks" show-overflow-tooltip />

      <el-table-column align="center" label="渠道二维码" prop="channelQrcodeUrl" show-overflow-tooltip>
        <template #default="{ row }">
          <el-image :preview-src-list="[row.channelQrcodeUrl]" :src="row.channelQrcodeUrl" />
        </template>
      </el-table-column>

      <el-table-column align="center" label="渠道url" prop="channelUrl" show-overflow-tooltip />

      <el-table-column align="center" label="操作" show-overflow-tooltip width="300">
        <template #default="{ row }">
          <el-button type="primary" @click="downLoad(row)">
            下载二维码
          </el-button>
          <el-button type="primary" @click="copy(row, $event)">
            复制url
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination v-if="state.total > 10" background :current-page="state.queryForm.pageNum" :layout="state.layout" :page-size="state.queryForm.pageSize" :total="state.total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />

    <channelAddDrawer ref="refEditConfigDrawer" :title="title" @noticeRefresh="getData" />
  </div>
</template>
<script>
export default { name: 'ChannelManage' }
</script>
<script setup>
import { onActivated } from 'vue'
import { Plus } from '@element-plus/icons'
import { reactive, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { exhibitionChannelList } from '@/api/exhibitionManage'
import { parseTime } from '@/utils/index'
import { debounce } from '@/utils/debounce'
import { useComponent } from './components/index'

import handleClipboard from '@/utils/clipboard'
// 引入组件
const { channelAddDrawer } = useComponent()
// 门票的状态
const field = {
  null: '暂无',
  USED: '已使用',
  WAIT_USED: '待使用',
  EXPIRED: '已过期',
  CLOSED: '已关闭',
  NOT_START: '未开始',
  CAN_USED: '可使用',
}
// 引入路由实例
const router = useRouter()
const route = useRoute()
// 引入组件
const loading = ref(false) // 列表动画加载

const title = ref('添加门票集合页配置') // 编辑弹框
const refEditConfigDrawer = ref(null)
const state = reactive({
  queryForm: { meetingCode: '', pageNum: 1, pageSize: 10 },
  total: 0,
  list: [],
  layout: 'prev, pager, next',
  type: 'EXHIBITION',
  typeOptions: [
    {
      label: '全部',
      value: '',
    },
    {
      label: '未开始',
      value: 'NOT_START',
    },
    {
      label: '可使用',
      value: 'CAN_USED',
    },
    {
      label: '待使用',
      value: 'WAIT_USED',
    },
    {
      label: '已使用',
      value: 'USED',
    },
    {
      label: '已过期',
      value: 'EXPIRED',
    },
    {
      label: '已关闭',
      value: 'CLOSED',
    },
  ],
})

const downLoad = (row) => {
  let xhr = new XMLHttpRequest()
  xhr.open('get', row.channelQrcodeUrl, true)
  //  xhr.setRequestHeader('Content-Type', `application/${type}`);
  xhr.responseType = 'blob'
  xhr.onload = function () {
    if (this.status == 200) {
      //接受二进制文件流
      console.log(this)
      var blob = this.response
      const blobUrl = window.URL.createObjectURL(blob)
      // 这里的文件名根据实际情况从响应头或者url里获取
      const a = document.createElement('a')
      a.href = blobUrl
      a.download = row.channelName
      a.click()
      window.URL.revokeObjectURL(blobUrl)
    }
  }
  xhr.send()
}

// const downloadPic = (row) => {
//   let imgsrc = row.channelQrcodeUrl
//   var image = new Image()
//   // 解决跨域canvas污染问题
//   image.setAttribute('crossOrigin', 'anonymous')
//   image.onload = function () {
//     let canvas = document.createElement('canvas')
//     canvas.width = image.width
//     canvas.height = image.height
//     let context = canvas.getContext('2d')
//     context.drawImage(image, 0, 0, image.width, image.height)
//     let url = canvas.toDataURL('image/png') // 得到图片的base64编码数据
//     let a = document.createElement('a')
//     a.download = 'download'
//     a.href = url
//     a.click()
//   }
//   image.src = imgsrc
// }

const copy = (row, event) => {
  handleClipboard(row.channelUrl, event)
}

// 获取初始化数据
const getData = async () => {
  loading.value = true
  const { data } = await exhibitionChannelList(state.queryForm)
  state.list = data.data
  state.total = data.total
  setTimeout(() => {
    loading.value = false
  }, 500)
}
// 筛选时间
const filterTime = (val) => {
  return parseTime(val)
}
// 下拉事件
const handleChangeSelect = (e) => {
  state.queryForm.status = e
}
// 查询
const handleQueryData = debounce(() => {
  state.queryForm.pageNum = 1
  getData()
})
// 条数
const handleSizeChange = debounce((pageSize) => {
  state.queryForm.pageSize = pageSize
  getData()
})
// 页码
const handleCurrentChange = debounce((pageNum) => {
  state.queryForm.pageNum = pageNum
  getData()
})
// 跳转订单详情
const handleJumpOrderDetail = (row) => {
  router.push({
    path: '/exhibitionManage/orderDetail',
    query: {
      orderNo: row.orderNo,
    },
  })
}

// 新增
const handleAdd = debounce(() => {
  title.value = '添加购票渠道'
  let obj = {
    meetingCode: state.queryForm.meetingCode
  }
  refEditConfigDrawer.value.handleOpen(obj)
})
onActivated(async () => {
  console.log('执行1111', route, route.query.meetingCode)
  state.queryForm.meetingCode = route.query.meetingCode
  await getData()
})
</script>
